import {TweenMax} from 'gsap'
import 'jquery-match-height'
import objectFitImages from 'object-fit-images'
import Rellax from 'rellax'
import './plugin/jquery.zip2addr'
import './plugin/jquery-inertiaScroll'
import 'modaal'

if (navigator.userAgent.indexOf('iPhone') > 0) {
  let body = document.getElementsByTagName('body')[0];
  body.classList.add('iPhone');
}

if (navigator.userAgent.indexOf('iPad') > 0) {
  let body = document.getElementsByTagName('body')[0];
  body.classList.add('iPad');
}

if (navigator.userAgent.indexOf('Android') > 0) {
  let body = document.getElementsByTagName('body')[0];
  body.classList.add('Android');
}

$(function() {
  $(window).on('scroll', function () {
    $('.labo-text').text($(this).scrollTop());
  });

  // match-height-js
  $('.js-match-height').matchHeight();

  // zip
  $('.js-zip').zip2addr('.js-addr');

  // ie object-fit
  objectFitImages();

  $(window).on('load', function () {
    $('.mask').removeClass('fadeout');
  });

  // ページ遷移
  $('a:not([href^="#"]):not([target]):not([href^=".modal-slider"])').on('click', function (e) {
    e.preventDefault(); // ナビゲートをキャンセル
    var url = $(this).attr('href'); // 遷移先のURLを取得
    if (url !== '') {
      $('.mask').addClass('fadeout');  // bodyに class="fadeout"を挿入
      setTimeout(function () {
        window.location = url;  // 0.8秒後に取得したURLに遷移
      }, 300);
    }
    return false;
  });

  // モーダル
  $('.js-modal-trigger').modaal({
    animation_speed: 350,
    overlay_opacity: .5,
    after_open: function () {
      setSlickPosition();
    }
  });
  $('.js-modal-trigger').on('click', function () {
    var slideNum = $(this).data('modal');
    var slider = $('.js-staff-modals');
    slider.slick('slickGoTo', slideNum - 1);
  });
  $('.js-modal-close').on('click', function (e){
    e.preventDefault();
    $('.js-modal-trigger').modaal('close');
  });
  function modalFixHeight () {
    $('.js-modal-trigger').on('click', function () {
      var width = $(window).width();
      var height = width * .75 / 2;
      if (width < 1280) {
        $('.slick-list').height('480px');
        $('.p-staff-modal-profile').height('480px');
      } else {
        $('.slick-list').height(height + 'px');
        $('.p-staff-modal-profile').height(height + 'px');
      }
    });
  }

  function setSlickPosition() {
    var slider = $('.js-staff-modals');
    slider.slick('setPosition');
  }

  // ページをなめらかにスクロール
  function heightContent() {
    $(window).on('load resize', function () {
      if ($(window).width() > 768) {
        var height = $('.js-smoothScroll').height();
        $('body').css('height', height + 'px');
      } else {
        $('body').removeAttr('style');
      }
    });
  }

  // トップページパララックス
  $(window).on('scroll', function () {
    var elem = $('.js-bg-fix');
    elem.each(function () {
      var elemOffset = $(this).offset().top;
      var scrollPos = $(window).scrollTop();
      var wh = $(window).height();
      var width = $(window).width();
      if (width > 767) {
        wh = wh + wh;
      } else {
        wh = wh + wh;
      }
      if (scrollPos > elemOffset - wh) {
        $('body').addClass('-bg-02');
        $(this).addClass('-is-show');
      } else {
        $('body').removeClass('-bg-02');
        $(this).removeClass('-is-show');
      }
    });
  });

  // スムーススクロール
  $('a[href^="#"]').click(function () {
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    var header = $('.js-header').height();
    position = position - header;
    if (target.css('opacity') === '0') {
      position -= 40;
    }
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  // セレクトボックスでchangeしたらvalの値にページ遷移
  $('.js-page-transition').on('change', function () {
    var value = $(this).val();
    window.location = value;
  });

  // 要素のフェードイン
  $(window).on('load scroll', function () {
    $('.fadeIn').each(function () {
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      var width = $(window).width();
      if (width > 768) {
        windowHeight += -300;
      } else {
        windowHeight += -50;
      }
      if (scroll > imgPos - windowHeight) {
        $(this).addClass('-is-show');
      }
    });
    $('.fadeIn-bg').each(function () {
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight) {
        $(this).addClass('-is-show');
      }
    });
    $('.fadeIn02').each(function () {
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight) {
        $(this).addClass('-is-show');
      }
    });
    $('.appear').each(function () {
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      var width = $(window).width();
      if (width > 768) {
        windowHeight += -300;
      } else {
        windowHeight += 150;
      }
      if (scroll > imgPos - windowHeight) {
        $(this).addClass('-is-show');
      }
    });
  });

  new Rellax('.rellax');

  // $(window).on('load resize', function () {
  //   var width = $(this).width();
  //   if (width > 767) {
  //     rellax({
  //       speed: -4
  //     });
  //   } else {
  //     rellax({
  //       speed: -2
  //     });
  //   }
  // });

  // PCとSPで出し分ける必要がある処理はここで実行する
  function responsiveCommon() {
    var width = $(window).width();

    if (width < 768) {
      toggleTopCopy('sp');
      displayNewsList(3);
      toggleFooterShow();
      svgSpRemoveAttr('.footer-left__snsArea');
      svgSpRemoveAttr('.p-top-concept-slider');
      svgSpRemoveAttr('.header__logo');
      svgSpRemoveAttr('.c-finish__phoneNumbers');
      svgSpRemoveAttr('.c-panel06__svg');
    } else if (width > 1280) {
      toggleTopCopy('pc');
      $('.js-match-height-only-pc').matchHeight();
      arrowAnimation();
      heightContent();
      modalFixHeight();
    } else {
      toggleTopCopy('pc');
      $('.js-match-height-only-pc').matchHeight();
      arrowAnimation();
      heightContent();
      modalFixHeight();
    }
  }
  $(window).on('load resize', function() {
    responsiveCommon();
  });
  $(window).on('load', function () {
    var width = $(window).width();
    if (width > 1280) {
      smoothScroll();
    }
  });

  function smoothScroll() {
    $('.box').inertiaScroll({
      parent: $(".js-smoothScroll"),
      parentDelta: 0.08
    });
  }

  function arrowAnimation() {
    // hoverした時に矢印を動かす
    $(".js-hover").on("mouseenter", function () {
      let $that = $(this).find(".c-arrows");
      TweenMax.to($that, .3, {
        left: "100%",
        ease: "circ.out",
        overwrite: true,
        onComplete: function () {
          TweenMax.set($that, {
            left: "-100%",
          }), TweenMax.to($that, .3, {
            left: "0",
            ease: "circ.in",
          })
        }
      });
    });
    $(".js-zoom-up").on("mouseenter", function () {
      $(this).find(".c-panel__img").addClass("-zoom-up");
      let $that = $(this).find(".c-arrows");
      TweenMax.to($that, .3, {
        left: "100%",
        ease: "circ.out",
        overwrite: true,
        onComplete: function () {
          TweenMax.set($that, {
            left: "-100%",
          }), TweenMax.to($that, .3, {
            left: "0",
            ease: "circ.in"
          })
        }
      });
    }).mouseleave(function () {
      $(this).find(".c-panel__img").removeClass("-zoom-up")
    });
    $(".js-hover-reverse").on("mouseenter", function () {
      let $that = $(this).find(".c-arrows");
      TweenMax.to($that, .3, {
        right: "100%",
        ease: "circ.out",
        overwrite: true,
        onComplete: function () {
          TweenMax.set($that, {
            right: "-100%",
          }), TweenMax.to($that, .3, {
            right: "0",
            ease: "circ.in"
          })
        }
      });
    });
  }

  // フッター
  function toggleFooterShow() {
    $(window).on('scroll', function () {
      var scroll = $(this).scrollTop();
      var $footer = $('.js-footer-bottom');
      if (scroll > 300) {
        $($footer).addClass('-is-show');
      } else {
        $($footer).removeClass('-is-show');
      }
    });
  }

  // トップページイントロ部分のsvgを出し分ける
  function toggleTopCopy(flag) {
    if (flag === 'sp') {
      $('.p-top-intro__ttl-img').find('use').attr('xlink:href', '#copy_sp');
      $('.p-top-intro__ttl-img').removeAttr('width', 'height');
    } else {
      $('.p-top-intro__ttl-img').find('use').attr('xlink:href', '#copy_pc');
      $('.p-top-intro__ttl-img').attr({ 'width': '216', 'height': '480' });
    }
  }

  // svgのwidthとheightを削除
  function svgSpRemoveAttr(target) {
    $(target).find('svg').removeAttr('width height');
  }

  // トップページのnews-list、記事の数を調整
  function displayNewsList(num) {
    var $item = $('.js-news-list').find('li');
    $item.css('display', 'none');
    for (var i = 0; i < num; i++) {
      $item.eq(i).css('display', 'block');
    }
  }
});
