
$(function(){
  // トップページ メインビジュアルのスライダー
  $(".slider-top").on("init", function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  });
  $(".slider-top").slick({
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    fade: true,
    speed: 2500,
    arrows: false,
    dots: true,
    dotsClass: 'p-top-slide-dots c-reset-slider-dots',
    appendDots: $(".slider-top").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
  })
  .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    nextSlide = getdoubleDigestNumer(nextSlide + 1);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
  });

  // labo,style02ページ下部のスライダー
  $('.slider01').on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
  .slick({
    autoplay: true,
    slidesToShow: 5,
    variableWidth: true,
    centerMode: true,
    dots: true,
    dotsClass: 'slider02-dots c-reset-slider-dots',
    appendDots: $(".slider01").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
    prevArrow: $(".slider01").siblings('.js-slide-indicator').find(".slick-prev"),
    nextArrow: $(".slider01").siblings('.js-slide-indicator').find(".slick-next"),
  })
  .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    nextSlide = getdoubleDigestNumer(nextSlide + 1);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
  });

  // トップページ コンセプト部分のスライダー
  $(".slider02").on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
  .slick({
    autoplay: true,
    slidesToShow: 5,
    variableWidth: true,
    centerMode: true,
    dots: true,
    dotsClass: 'slider02-dots c-reset-slider-dots',
    appendDots: $(".slider02").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
    prevArrow: $(".slider02").siblings('.js-slide-indicator').find(".slick-prev"),
    nextArrow: $(".slider02").siblings('.js-slide-indicator').find(".slick-next")
  })
  .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    nextSlide = getdoubleDigestNumer(nextSlide + 1);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
  });

  // トップページworks部分のスライダー
  $(".slider03").on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
    let text = $(this).find(".js-get-slide-title").eq(slick.currentSlide).attr('alt');
    $(this).siblings('.js-slide-indicator').find(".js-show-slide-title").text(text);
  })
  .slick({
    autoplay: true,
    dots: true,
    dotsClass: 'slider03-dots c-reset-slider-dots',
    appendDots: $(".slider03").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
    prevArrow: $(".slider03").siblings('.js-slide-indicator').find(".slick-prev"),
    nextArrow: $(".slider03").siblings('.js-slide-indicator').find(".slick-next")
  })
  .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    nextSlide = getdoubleDigestNumer(nextSlide + 1);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
    let text = $(this).find(".js-get-slide-title").eq(nextSlide).attr('alt');
    $(this).siblings('.js-slide-indicator').find(".js-show-slide-title").text(text);
  });

  // モデルハウス見学会ページのスライダー
  $(".slider04-01").on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
    .slick({
      autoplay: false,
      dots: true,
      dotsClass: 'slider02-dots c-reset-slider-dots',
      appendDots: $(".slider04-01").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
      prevArrow: $(".slider04-01").siblings('.js-slide-indicator').find(".slick-prev"),
      nextArrow: $(".slider04-01").siblings('.js-slide-indicator').find(".slick-next")
    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
    });

  $(".slider04-02").on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
    .slick({
      autoplay: false,
      dots: true,
      dotsClass: 'slider02-dots c-reset-slider-dots',
      appendDots: $(".slider04-02").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
      prevArrow: $(".slider04-02").siblings('.js-slide-indicator').find(".slick-prev"),
      nextArrow: $(".slider04-02").siblings('.js-slide-indicator').find(".slick-next")
    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
    });


      //slickスライダー初期化
  $(".thumbnail-slider").on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
      .slick({
        autoplay: false,
        arrows: false,
        fade: false,
        slidesToShow: 3,
        centerMode: true,
        variableWidth: true,
        infinite: false,
        responsive: [{
          breakpoint: 768,
          settings: 'unslick'
        }]
      });
      $(window).on("load", function () {
        $(".thumbnail-slider").slick("slickGoTo", 0, false);
      });

  $(window).resize(function () {
    $('.thumbnail-slider').slick('resize');
  });

  if ($(".thumbnail-slider").length > 0) {
    var slider = ".thumbnail-slider"; // スライダー
    var thumbnailItem = ".c-thumbSlider-thumbnail-list .c-thumbSlider-thumbnail-item"; // サムネイル画像アイテム

    // サムネイル画像アイテムに data-index でindex番号を付与
    $(thumbnailItem).each(function () {
      var index = $(thumbnailItem).index(this);
      $(this).attr("data-index", index);
    });

    // スライダー初期化後、カレントのサムネイル画像にクラス「thumbnail-current」を付ける
    // 「slickスライダー作成」の前にこの記述は書いてください。
    $(slider).on('init', function () {
      var index = $(".slide-item.slick-slide.slick-current").attr("data-slick-index");
      $(thumbnailItem + '[data-index="' + index + '"]').addClass("-is-thumbnail-current");
    });

    //サムネイル画像アイテムをクリックしたときにスライダー切り替え
    $(thumbnailItem).on('click', function () {
      if (finishTime - startTime < 400) {
        var index = $(this).attr("data-index");
        $(slider).slick("slickGoTo", index, false);
      }
    });


    //サムネイル画像のカレントを切り替え
    $(slider).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $(thumbnailItem).each(function () {
        $(this).removeClass("-is-thumbnail-current");
      });
      $(thumbnailItem + '[data-index="' + nextSlide + '"]').addClass("-is-thumbnail-current");
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(".js-indicator-current-num").text(nextSlide);

      var itemPosition = getPositionItem(nextSlide);
      var thumbnailRightPosition = getPositionThumbnail();
      var thumbnailLeftPosition = getPositionLeft();
      var NewSeePosition = ((78 * nextSlide) - 823);
      var negativePosition = (78 * (nextSlide - 1));
      if (nextSlide > currentSlide) {
        if (itemPosition > thumbnailRightPosition - 78) {
          $('.c-thumbSlider-thumbnail-list-outer').animate({ scrollLeft: NewSeePosition });
        }
      } else if (thumbnailLeftPosition > itemPosition) {
        $('.c-thumbSlider-thumbnail-list-outer').animate({ scrollLeft: negativePosition });
      }
    });

    var num = $(".c-thumbSlider-thumbnail-list-inner li").length;
    $(".c-thumbSlider-thumbnail-list-inner").css("width", (num) * 78 + "px");
  }

  $('.wp-pagenavi').find('.page, .current').each(function () {
    var num = $(this).text();
    if (isNaN(num) === false) {
      num = getdoubleDigestNumer(num);
      $(this).text(num);
    }
  });

  //数字を2桁にする関数
  function getdoubleDigestNumer(number) {
    return ("0" + number).slice(-2);
  }

  // サムネイルスライダーのサムネイル部分をドラッグで移動できるようにする
  var initialPos = 0;
  var movePos = 0;
  var offsetLeft = 0;
  var scrollLeft = 0;
  var scrollLeftInParent = 0;
  var scrollFlag = false;
  var startTime = 0;
  var finishTime = 0;
  $('.js-thumbnail-drag').on('mousedown', function (e) {
    e.preventDefault();
    initialPos = e.clientX - $(this).offset().left;
    offsetLeft = $(this).offset().left;
    scrollLeftInParent = -$(this).position().left;
    scrollFlag = true;
    startTime = e.timeStamp;
  });
  $('.js-thumbnail-drag').on('mouseup', function (e) {
    e.preventDefault();
    scrollFlag = false;
    finishTime = e.timeStamp;
  });
  $('.js-thumbnail-drag').on('click', function (e) {
    if (finishTime - startTime > 1000) {
      e.preventDefault();
    }
  });
  $('.js-thumbnail-drag').on('mouseleave', function (e) {
    e.preventDefault();
    scrollFlag = false;
  });
  $('.js-thumbnail-drag').on('mousemove', function (e) {
    e.preventDefault();
    movePos = e.clientX - offsetLeft;
    if (scrollFlag === true) {
      scrollLeft = (initialPos - movePos + scrollLeftInParent);
      $(this).parent('div').scrollLeft(scrollLeft);
    }
  });
  // サムネイルリストの見えている幅を取得
  function getThumbnailWidth () {
    var w = $('.c-thumbSlider-thumbnail-list-outer').width();
    return w;
  }
  // 現在見えているサムネイルリストの左端の位置を取得
  function getPositionLeft () {
    var position = $('.c-thumbSlider-thumbnail-list-outer').find('li').eq(0).offset().left;
    var posParent = $('.c-thumbSlider-thumbnail-list-outer').offset().left;
    var seePosition = posParent - position;
    return seePosition;
  }
  // 現在の見えている位置の右端を取得
  function getPositionThumbnail () {
    var seePosition = getPositionLeft();
    var w = getThumbnailWidth();
    var trueSeePosition = seePosition + w;
    return trueSeePosition;
  }
  // サムネイルリストのカレントの位置を取得
  function getPositionItem (item) {
    var itemPosition = (item  * 78) - 78;
    return itemPosition;
  }

  // スタッフ紹介ページ モーダルスライダー
  $('.js-staff-modals').on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).find('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).find('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
    .slick({
      autoplay: false,
      fade: true,
      dots: false,
      swipe: false,
      responsive: [
        {
        breakpoint: 768,
        settings: {
          swipe: true
        }
      }
    ],
      dotsClass: 'slider02-dots c-reset-slider-dots',
      prevArrow: $('.js-staff-modals').find(".c-detail-paging__link.slick-prev"),
      nextArrow: $('.js-staff-modals').find(".c-detail-paging__link.slick-next"),
    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      var width = $(window).width();
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(this).find('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);

      if (width > 767) {
        $(this).find('.p-staff-modal-profile').animate({ scrollTop: 0 }, 0, "leaner");
      } else {
        $('.modaal-wrapper').animate({ scrollTop: 0 }, 0, 'leaner');
      }
    });

    // リノベーションページスライダー
    $('.slider-unit01').on('init', function (event, slick) {
      var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
      var totalNum = getdoubleDigestNumer(slick.slideCount);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
    })
    .slick({
      autoplay: true,
      autoplaySpeed: 3500,
      dots: true,
      dotsClass: 'c-slider-unit01 c-reset-slider-dots',
      appendDots: $(".slider-unit01").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
      prevArrow: $('.slider-unit01').siblings('.js-slide-indicator').find('.slick-prev'),
      nextArrow: $('.slider-unit01').siblings('.js-slide-indicator').find('.slick-next')
    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
    });

  $('.slider-unit02').on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
    .slick({
      autoplay: true,
      autoplaySpeed: 3500,
      dots: true,
      dotsClass: 'c-slider-unit02 c-reset-slider-dots',
      appendDots: $(".slider-unit02").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
      prevArrow: $('.slider-unit02').siblings('.js-slide-indicator').find('.slick-prev'),
      nextArrow: $('.slider-unit02').siblings('.js-slide-indicator').find('.slick-next')
    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
    });

  $('.slider-unit03').on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
    .slick({
      autoplay: true,
      autoplaySpeed: 3500,
      dots: true,
      dotsClass: 'c-slider-unit03 c-reset-slider-dots',
      appendDots: $(".slider-unit03").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
      prevArrow: $('.slider-unit03').siblings('.js-slide-indicator').find('.slick-prev'),
      nextArrow: $('.slider-unit03').siblings('.js-slide-indicator').find('.slick-next')
    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
    });

  $('.slider-unit04').on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
    .slick({
      autoplay: true,
      autoplaySpeed: 3500,
      dots: true,
      dotsClass: 'c-slider-unit01 c-reset-slider-dots',
      appendDots: $(".slider-unit04").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
      prevArrow: $('.slider-unit04').siblings('.js-slide-indicator').find('.slick-prev'),
      nextArrow: $('.slider-unit04').siblings('.js-slide-indicator').find('.slick-next')
    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
    });

  $('.slider-unit05').on('init', function (event, slick) {
    var currentNum = getdoubleDigestNumer(slick.currentSlide + 1);
    var totalNum = getdoubleDigestNumer(slick.slideCount);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(currentNum);
    $(this).siblings('.js-slide-indicator').find('.js-indicator-total-num').text(totalNum);
  })
    .slick({
      autoplay: true,
      autoplaySpeed: 3500,
      dots: true,
      dotsClass: 'c-slider-unit01 c-reset-slider-dots',
      appendDots: $(".slider-unit05").siblings('.js-slide-indicator').find(".c-slider-nav__dots"),
      prevArrow: $('.slider-unit05').siblings('.js-slide-indicator').find('.slick-prev'),
      nextArrow: $('.slider-unit05').siblings('.js-slide-indicator').find('.slick-next')
    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      nextSlide = getdoubleDigestNumer(nextSlide + 1);
      $(this).siblings('.js-slide-indicator').find('.js-indicator-current-num').text(nextSlide);
    });
});
