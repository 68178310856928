$(function(){
  if ($('.js-sideNav').length > 0) {
    // laboページサイドナビゲーション
    var fadein_flag = false;

    $(".js-sideNav-dot").on("click", function () {
      var speed = 400;
      var href = $(this).next('span').find("a").attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      fadein_flag = false;
      $("html, body").animate({ scrollTop: position }, speed, "swing");
      return false;
    });

    $(".js-sideNav-trigger").on("click", function () {
      if ($(this).hasClass("-is-open")) {
        $(this).removeClass("-is-open");
        $(this).next("ul").find("a").removeClass("-is-open");
        fadein_flag = true;
      } else {
        $(this).addClass("-is-open");
        $(this).next("ul").find("a").addClass("-is-open");
        fadein_flag = true;
      }
    });
    $(".js-sideNav-link").on("click", function () {
      if ($(this).hasClass("-is-open")) {
        fadein_flag = true;
      }
    });
    $(".js-sideNav-item").on("click", function () {
      fadein_flag = true;
    });
    var margin = 400,   //ウインドウ上部からどれぐらいの位置で変化させるか
      sectionTop = new Array, //sectionのTop位置格納用
      current = -1;   //現在のカレント位置

    //(1)各sectionの縦位置を取得
    $('.style-section').each(function (i) {
      sectionTop[i] = $(this).offset().top;
    });

    //init
    changeNavCurrent(0);

    //スクロールした時の処理
    $(window).scroll(function () {
      var scrollY = $(window).scrollTop();
      var window_h = $(window).height() / 2;
      var firstElement = $("#panel-sec").offset().top;
      var secondElement = $("#style02").offset().top;
      var footerElement = $(".cv").offset().top;
      var sideNav = $(".js-sideNav");
      fadein_flag;

      if (scrollY > firstElement - window_h) {
        if (fadein_flag === false) {
          sideNav.addClass("-is-fadeIn");
          sideNav.find(".js-sideNav-trigger").addClass("-is-open")
          sideNav.find("a").addClass("-is-open");
        }
        //fadein_flag = true;
      } else {
        sideNav.removeClass("-is-fadeIn");
        sideNav.find("a").removeClass("-is-open");
        fadein_flag = false;
      }

      if (scrollY > secondElement - window_h) {
        if (sideNav.find("a").hasClass("-is-open")) {
          if (fadein_flag === false) {
            sideNav.find("a").removeClass("-is-open");
            sideNav.find(".js-sideNav-trigger").removeClass("-is-open");
          }
        }
      }

      if (scrollY > footerElement - window_h) {
        sideNav.removeClass("-is-fadeIn");
        sideNav.find(".js-sideNav-trigger").removeClass("-is-open")
        sideNav.find("a").removeClass("-is-open");
        fadein_flag = false;
      }
      //(2)各セクションの位置とスクロール位置を比較して、条件にあったらchangeNavCurrentを実行
      for (var i = sectionTop.length - 1; i >= 0; i--) {
        if (scrollY > sectionTop[i] - margin) {
          changeNavCurrent(i);
          break;
        }
      }
    });
  }

  //(3)ナビの処理
  function changeNavCurrent(curNum) {
    if (curNum != current) {
      current = curNum;
      var curNum2 = curNum + 1;
      $('.p-labo__sideNav li').removeClass('-is-current');
      $('.p-labo__sideNav li:nth-child(' + curNum2 + ')').addClass('-is-current');
    }
  }

  function scrollbarSpOnly() {
    var width = $(window).width();

    if (width < 768) {
      //スクロールバー
      $(".js-scrollbar-wrapper").on("scroll", function () {
        var scroll = $(this).scrollLeft();
        var width = $(this).find(".js-scrollbar-inner").width();
        var scrollbar = $(this).next(".js-scrollbar").width();
        var visivle_area = scrollbar + $(this).offset().left;
        var $thumb = $(this).next(".js-scrollbar").find(".js-scrollbar-thumb");
        $thumb.width(scrollbar * visivle_area / width);
        $thumb.css("left", scroll * ((scrollbar) / width));
      });
      $(window).on("load", function () {
        $(".js-scrollbar-wrapper").each(function () {
          var width = $(this).find(".js-scrollbar-inner").width();
          var scrollbar = $(this).next(".js-scrollbar").width();
          var visivle_area = scrollbar + $(this).offset().left;
          var $thumb = $(this).next(".js-scrollbar").find(".js-scrollbar-thumb");
          $thumb.width(scrollbar * visivle_area / width);
        });
      });
      $(".js-scrollbar-inner").each(function () {
        var totalWidth = 0;
        $(this).find("li").each(function () {
          totalWidth += parseInt($(this).outerWidth(true), 10);
        });
        $(this).width(totalWidth);
        $(this).css("display", "flex");
      });
    }
  }

  scrollbarSpOnly();

  $(window).resize(function () {
    scrollbarSpOnly();
  });
});
