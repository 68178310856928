$(function(){
  // ヘッダーにクラスを付与
  $(window).on("scroll", function () {
    var $header = $(".js-header");
    var $pageTop = $('.js-pagetop');
    var scroll = $(this).scrollTop();
    if (scroll > 300) {
      $header.addClass('-is-show');
      $pageTop.addClass('-is-show');
    } else {
      $header.removeClass('-is-show');
      $pageTop.removeClass('-is-show');
    }
  });
  // sp時ナビゲーションメニュー開閉
  $('.js-toggle-target').on('click', function () {
    var $header = $('.js-header');
    // var scrollto = $('body').css('top');
    // scrollto = scrollto.replace('-', '');
    // var scrollpos = $(window).scrollTop();

    if ($(this).hasClass('-is-open') === true) {
      $('.js-toggle-target').each(function () {
        $(this).removeClass('-is-open');
      });
      $header.removeClass('-is-open');
      $header.find('.header-inner').removeClass('-is-open');
      $(this).removeClass('-is-open');
      // $('body').css({ 'position': 'static', 'top': 0, 'width': 'auto', 'height': 'auto', 'overflow': 'initial' });
      // $('body,html').animate({ scrollTop: scrollto }, 0);
    } else {
      $('.js-toggle-target').each(function(){
        $(this).addClass('-is-open');
      });
      $header.addClass('-is-open');
      $header.find('.header-inner').addClass('-is-open');
      $(this).addClass('-is-open');
      // $('body').css({ 'position': 'fixed', 'top': -scrollpos, 'width': '100%', 'height': '100%', 'overflow': 'hidden' });
      //var pos = 'top:' + scrollpos + 'px;';
      //$('body').attr('style', pos);
    }
  });
  $(window).on('scroll', function () {
    var scrollLeft = $(this).scrollLeft();
    $('.header-inner').css('transform', 'translateX(' + (-scrollLeft) + 'px)');
  });
});
