$(function () {
  var elem1 = $('.js-sec01').offset().top;
  var elem2 = $('.js-sec02').offset().top;
  var elem3 = $('.js-sec03').offset().top;
  $(window).on('scroll', function () {
    var scroll = $(window).scrollTop();
    var wh = $(window).height();

    if (scroll > elem1 - wh) {
      $('.-bg-fix-01').addClass('-is-show');
    } else {
      $('.-bg-fix-01').removeClass('-is-show');
    }
    if (scroll > elem2 - wh + 100) {
      $('.-bg-fix-02').addClass('-is-show');
    } else {
      $('.-bg-fix-02').removeClass('-is-show');
    }
    if (scroll > elem3 - wh + 100) {
      $('.-bg-fix-03').addClass('-is-show');
    } else {
      $('.-bg-fix-03').removeClass('-is-show');
    }
  });
});
